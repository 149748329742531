import {Component} from "@base/Base";
import Home from '@pages/Home'
import Report from '@pages/Report'
import Blog from '@pages/Blog'
import WantToHelp from '@pages/WantToHelp'
import NeedHelp from '@pages/NeedHelp'
import About from '@pages/About'
import Projects from "@pages/Projects";
import News from "@pages/News";
import OurWards from "@pages/OurWards";

import {
    Route
} from "react-router-dom";

class BaseContent extends Component {

    render() {

        let $app = this.app();

        return $app.data.lang === 'ru' ? this.content($app) : (
            <div className={$app.data.lang}>
                {this.content($app)}
            </div>
        )
    }

    content = ($app) => {

        let rewrite =  !Object.entries($app.data.menuLinks).map(([key, value]) => value).includes(window.location.pathname)

        return (
            <>
                <Route path={$app.data.menuLinks.home} component={Home} exact/>
                <Route path={$app.data.menuLinks.wantToHelp} component={WantToHelp} exact/>
                <Route path={$app.data.menuLinks.needHelp} component={NeedHelp} exact/>
                <Route path={$app.data.menuLinks.projects} component={Projects}/>
                <Route path={$app.data.menuLinks.report} component={Report} exact/>
                <Route path={$app.data.menuLinks.about} component={About} exact/>

                <Route path={$app.data.menuLinks.blog + '/:n'} strict={true} exact={true} render={(r) => {
                    return <Blog type={r.match.params.n ? 'news' : 'blog'}/>
                }}/>

                <Route path={$app.data.menuLinks.blog} strict={true} exact={true} render={(r) => {
                    return <Blog type={r.match.params.n ? 'news' : 'blog'}/>
                }}/>

                <Route path={'/news/:id'} render={(r) => {
                    return <News id={r.match.params.id} type={'news'}/>
                }}/>

                <Route path={'/info/:id'} render={(r) => {
                    return <News id={r.match.params.id} type={'info'}/>
                }}/>

                <Route path={'/our-wards'} render={(r) => {
                    return <OurWards/>
                }}/>

                {rewrite ? (<Route path="*" component={Home}/>) : ''}

            </>
        )
    }
}

export default BaseContent